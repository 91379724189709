.products {
	padding: 40px 30px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
	// box-shadow: $box-shadow;
	background-color: white;

	> h2 {
		font-family: $nunito;
		font-weight: 800;
		color: $black-800;
		font-size: 31px;
		line-height: 42px;
		margin-bottom: 24px;
		flex-basis: calc(50% - 10px);
		margin: 0 20px 25px 0;

		@media screen and (min-width: 1200px) {
			flex-basis: 100%;
		}
	}

	.search-container {
		flex-basis: calc(50% - 10px);
		margin-bottom: 25px;
		position: relative;

		@media screen and (min-width: 1200px) {
			flex-basis: 100%;
			display: flex;
			justify-content: space-between;
		}

		.search {
			display: inline-block;
			border: 1px solid $black-600;
			// border-radius: 10px;
			position: relative;
			width: 100%;

			@media (min-width: 1200px) {
				width: calc(25% - 20px);
			}

			form {
				display: flex;
				align-items: center;

				input {
					font-family: $nunito;
					font-weight: normal;
					text-transform: uppercase;
					font-size: 12px;
					color: $purple;
					background: none;
					border: 0;
					padding: 0;
					margin: 0;
					min-height: 20px;
					padding: 12px 0 12px 20px;
					flex-grow: 1;

					&::placeholder {
						color: $black-600;
					}
				}

				button {
					font-size: 16px;
					background: none;
					padding-right: 20px;

					i {
						color: $black-600;
					}
				}
			}
		}

		.filters-bar {
			display: none;
			font-family: $nunito;
			font-weight: normal;
			width: 75%;

			@media screen and (min-width: 1200px) {
				align-items: center;
				justify-content: flex-end;
			}

			span {
				color: $black-800;
			}

			ul {
				display: inline-block;
				margin: 0 0 0 6px;

				li {
					display: inline-block;
					background-color: $black-600;
					// border-radius: 10px;
					padding: 7px 20px;
					cursor: pointer;
					margin-right: 5px;
					margin-bottom: 5px;

					&:last-of-type {
						margin-right: 0;
					}

					a {
						color: $white;
						text-decoration: none;
					}

					&.selected {
						background-color: $black-800;
					}
				}
			}
		}
	}

	aside {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 25px;

		@media screen and (min-width: 1200px) {
			width: 25%;
		}

		.menu {
			flex-basis: calc(50% - 10px);
			margin-right: 20px;
			position: relative;
			background-color: $white;

			@media screen and (min-width: 1200px) {
				flex-basis: 100%;
			}

			.head {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				background: $red-500;
				padding: 12px 0;
				// border-radius: 10px;

				h2 {
					font-family: $nunito;
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					text-transform: uppercase;
					color: $white;
					cursor: pointer;
					flex-grow: 1;
					padding-left: 20px;
				}

				.products-link,
				.experiences-link {
					display: inline-block;
					color: $white;
					font-size: 16px;
					background: none;
					border: 0;
					margin: 0 2px;
					padding: 2px;
					opacity: .6;
					cursor: pointer;
				}

				.experiences-link {
					@media screen and (min-width: 1200px) {
						margin-right: 20px;
					}
				}

				.drop {
					color: $white;
					font-size: 20px;
					background: 0 0;
					border: 0;
					margin: 0 20px 0 2px;
					padding: 0;
					cursor: pointer;
					transform: translateY(-4px);

					@media screen and (min-width: 1200px) {
						display: none;
					}
				}

				.selected {
					opacity: 1;
				}

				ul {
					display: none;
					position: absolute;
					border-top: 0;
					width: 100%;
					top: 100%;
					left: 0;
					z-index: 10;
					background-color: $white;
					box-shadow: $box-shadow;
					// border-radius: 0 0 10px 10px;

					@media screen and (min-width: 1200px) {
						display: block;
						top: 70px;
						box-shadow: none;
					}

					li {
						display: block;
						position: relative;
						padding: 12px 20px 0;

						@media screen and (min-width: 1200px) {
							padding: 12px 0;
						}

						&.subcategorie {
							text-indent: 1em
						}

						&.sub-subcategorie {
							text-indent: 2em
						}

						&:last-of-type {
							a {
								border-bottom: none;
							}
						}

						a {
							font-family: $nunito;
							font-weight: normal;
							font-size: 14px;
							color: $black-800;
							background: 0;
							border: 0;
							cursor: pointer;
							display: block;
							margin: 0;
							text-align: left;
							width: 100%;
							text-decoration: none;
							padding-bottom: 12px;
							border-bottom: 1px solid $black-600;

							@media screen and (min-width: 1200px) {
								border-bottom: none;
								padding-bottom: 0;
							}

							&:hover {
								color: $red;
							}

							span {
								margin-left: 5px;
							}
						}
					}

					.hidden {
						display: none;
					}
				}
			}
		}
	}

	.filters-bar-responsive {
		flex-basis: calc(50% - 10px);
		display: none;
		// border-radius: 10px;
		overflow: hidden;
		position: relative;

		select {
			color: $white;
			background-color: $black-600;
			font-size: 12px;
			line-height: 21px;
			text-transform: uppercase;
			padding: 12px 20px;
			width: 100%;
			position: relative;
			display: block;
			font-weight: 100;
			height: 100%;
			-webkit-appearance: none;
  			-moz-appearance: none;
		}

		.drop {
			position: absolute;
			top: calc(50% - 4px);
			right: 20px;
			transform: translateY(-50%);
			background: 0 0;
			border: 0;
			font-size: 20px;
			color: $white;

			@media screen and (min-width: 1200px) {
				display: none;
			}
		}

		.fa-sort-down {
			pointer-events: none;
		}

	}

	&__container {
		display: block;
		width: 100%;

		@media (min-width: 1200px) {
			display: flex;
			min-height: 300px;
		}
	}


	.main {
		width: 75%;
		padding: 0 0 0 10px;

		@media screen and (min-width: 1200px) {
			padding: 0;
		}

		.highlights {
			position: relative;
			width: 100%;

			.img-block {
				background-image: url("/assets/img/general/products-banner-small.jpg");
				background-position: center center;
				background-size: cover;
				border: 0;
				height: 255px;
				padding: 0;
				position: relative;
				margin: 0 0 20px;
				width: 100%;
				// border-radius: 10px;

				.text-container {
					top: 0;
					right: 0;
					padding: 20px 30px 20px 20px;
					position: absolute;
					text-align: right;

					h2 {
						font-family: $nunito;
						font-weight: bold;
						font-size: 30px;
						color: $white;
						text-transform: uppercase;
						margin-bottom: 10px;
						padding: 0;
					}

					p {
						font-family: $nunito;
						font-weight: normal;
						font-size: 14px;
						color: $white;
					}
				}
			}
			.products-block {
				display: grid;
				// flex-wrap: wrap;
				grid-gap: 20px 22px;
				width: 100%;

				@media screen and (min-width: 1200px) {
					grid-template-columns: 1fr 1fr
				}

				.product {
					font-family: $nunito;
					flex-basis: 100%;
					background-color: $white;
					box-shadow: $box-shadow;
					max-height: 180px;

					@media screen and (min-width: 1200px) {
						flex-basis: calc(50% - 10px);
					}

					a {
						background: none;
						border: 0;
						cursor: pointer;
						padding: 16px;
						margin: 0;
						text-decoration: none;
						display: flex;
						height: 100%;

						div {
							flex-basis: calc(60% - 10px);
							display: flex;
							flex-direction: column;
							padding-block: 8px;
						}

						.new-label {
							width: fit-content;
							margin-bottom: 8px;
							padding: 4px 6px;
							text-transform: uppercase;
							background: $red-500;
							font-size: 10px;
							line-height: 13px;
							color: $white;
							font-weight: 700;
							letter-spacing: .5px;
						}

						.name {
							flex: 1;
							color: $black-800;
							font-size: 16px;
							line-height: 22px;
							font-weight: 700;
							text-transform: uppercase;
						}

						.description {
							color: $black-800;
							font-size: 12px;
							font-weight: 600;
							line-height: 1.2;
							text-transform: uppercase;
							margin-bottom: 8px;
						}

						.points {
							margin-top: 10px;
							color: $black-800;
							font-size: 16px;
							line-height: 22px;
						}

						img {
							display: block;
							width: 100%;
							flex-basis: calc(40% - 10px);
							max-width: calc(40% - 10px);
							margin-right: 20px;
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	.table {
		display: none;

		.table-header {
			margin-bottom: 20px;

			@media screen and (min-width: 1200px) {
				margin-top: 15px;
			}

			.breadcrumbs {
				display: inline-block;

				li {
					font-family: $nunito;
					font-weight: normal;
					color: $black-800;
					font-size: 16px;
					display: inline-block;
					cursor: pointer;
				}
			}

			.favorites-widget {
				float: right;

				a {
					font-family: $nunito;
					font-weight: normal;
					color: $white;
					font-size: 12px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;

					&::before {
						content: '\f005';
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						font-size: 14px;
						color: $white;
						margin-right: 5px;
					}
				}
			}
		}

		.product-list {
			display: flex;
			flex-direction: column;
			width: 100%;

			@media screen and (min-width: 1200px) {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.item {
				width: 100%;
				cursor: pointer;
				position: relative;
				background-color: $white;
				box-shadow: $box-shadow;
				// border-radius: 10px;
				margin-bottom: 20px;
				padding: 20px;
				display: flex;
				align-items: center;

				@media screen and (min-width: 1200px) {
					width: calc(50% - 10px);
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				.item-image {
					overflow: hidden;
					height: 100%;
					flex-basis: calc(40% - 10px);
					margin-right: 20px;
					position: relative;
					z-index: 0;
					text-align: center;
					max-height: 120px;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				.item-contents {
					font-family: $nunito;
					height: 100%;
					flex-basis: calc(60% - 10px);

					.item-name {
						color: $purple-light;
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						text-transform: uppercase;
					}

					.item-brand {
						color: $black-800;
						font-weight: 700;
						font-size: 16px;
						line-height: 22px;
						text-transform: uppercase;
					}

					.item-rate {
						color: #5a5a5a;
						font-size: 10px;
						margin-top: 10px;
					}

					.item-points {
						color: $black-800;
						font-size: 16px;
						margin-top: 20px;

						span {
							font-weight: 700;
						}
					}
				}
			}

			.empty {
				cursor: default;

				&:hover {
					border: 1px solid $white;
					border-bottom: 1px solid #8e8e8e;
					border-right: 1px solid #8e8e8e;
				}
			}
		}

		.paginator {
			margin-top: 40px;

			span {
				font-family: $nunito;
				font-weight: normal;
				font-size: 16px;
				color: $black-800;
				margin-right: 14px;
			}

			ul {
				display: inline-block;

				li {
					display: inline-block;
					margin: 0 3px;

					a {
						font-family: $nunito;
						font-weight: normal;
						font-size: 10px;
						color: $white;
						background: #e0e0e0;
						border: 0;
						cursor: pointer;
						margin: 0;
						padding: 3px 6px 2px;
						min-width: 20px;
						text-decoration: none;
					}
				}

				.selected {
					a {
						background: $red;
					}
				}
			}
		}
	}

	.product-detail {
		position: relative;
		width: 100%;
		display: none;
		padding: 30px;
		background-color: $white;
		box-shadow: $box-shadow;
		// border-radius: 10px;

		.product-detail-header {
			margin-bottom: 20px;
			display: flex;

			h2 {
				font-family: $nunito;
				font-weight: 700;
				font-size: 16px;
				color: $black-800;
				text-transform: uppercase;
				width: auto;
				padding: 0;
				flex-grow: 1;
			}

			.favorites-widget {
				margin-left: 20px;

				a {
					font-family: $nunito;
					font-weight: normal;
					color: $black-800;
					font-size: 10px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;
					text-transform: uppercase;

					&::before {
						content: '\f005';
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						font-size: 14px;
						color: $black-800;
						margin-right: 5px;
					}
				}
			}
		}

		.product-detail-container {
			display: flex;
			flex-wrap: wrap;

			.product-detail-image {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-basis: calc(50% - 10px);
				margin-right: 20px;

				img {
					display: block;
					width: 100%;
					max-height: 300px;
					object-fit: contain;
				}
			}

			.product-detail-description {
				flex-basis: 100%;
				margin-top: 24px;
				padding-top: 24px;
				order: 1;
				border-top: 1px solid $black-200;

				div {
					height: 100%;

					p {
						font-family: $nunito;
						font-weight: normal;
						font-size: 16px;
						line-height: 22px;
						color: $black-800;
					}
				}

				p {
					font-size: 16px;
					line-height: 22px;
				}
			}

			.product-detail-data {
				position: relative;
				flex-basis: calc(50% - 10px);
				display: flex;
				align-items: center;

				.data-reference {
					font-family: $nunito;
					font-weight: 500;
					font-size: 14px;
					color: #252525;
					padding: 20px 20px 20px 0;
					position: absolute;
					top: 0;
				}

				.data-wrapper {
					padding: 20px 0 20px 20px;
					width: 100%;

					.points {
						font-family: $nunito;
						font-weight: 600;
						font-size: 31px;
						color: $black-800;
						line-height: 1.2;

						span {
							font-weight: 800;
						}
					}

					.stock {
						font-family: $nunito;
						font-weight: normal;
						font-size: 16px;
						color: $black-800;
						line-height: 1.2;

						span {
							font-weight: bold;
						}
					}

					.available-points {
						font-family: $nunito;
						font-size: 16px;
						color: $black-800;
						line-height: 1.2;
						margin-top: 8px;

						span {
							font-weight: bold;
						}
					}

					.request-rate {
						font-family: $nunito;
						font-weight: 500;
						font-size: 10px;
						color: #252525;
						line-height: 1.2;

						span {
							display: inline-block;
						}
					}

					.change-points {
						font-family: $nunito;
						font-weight: 500;
						background: $red-500;
						border: 0;
						// border-radius: 10px;
						color: $white;
						font-size: 14px;
						padding: 12px 16px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						font-weight: normal;
						margin-top: 8px;
						position: relative;
						text-align: left;
						width: 100%;
						padding-left: 20px;
						display: block;

						span {
							font-weight: 600;
							margin-left: 5px;
						}

						&::after {
							content: '\f291';
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							font-size: 14px;
							color: $white;
							position: absolute;
							right: 15px;
						}
					}

					.add-favorite {
						font-family: $nunito;
						font-weight: 500;
						background: $black-800;
						border: 0;
						// border-radius: 10px;
						color: $white;
						font-size: 14px;
						padding: 12px 16px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						font-weight: normal;
						margin-top: 8px;
						position: relative;
						text-align: left;
						width: 100%;
						display: block;

						span {
							font-weight: 600;
							margin-left: 5px;
						}

						&::after {
							content: '\f005';
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							font-size: 14px;
							color: $white;
							position: absolute;
							right: 15px;
						}
					}

					.favorite {
						background: $purple-light;
					}
				}
			}
		}

		.product-detail-other-images {
			border: 1px solid #8e8e8e;
			border-top: 0;
			height: 150px;
			overflow: hidden;
			position: relative;

			.container {
				width: 100%;
				height: 100%;

				.slider {
					transform: translateX(0px);
				}
			}
		}
	}

	.product-order {
		display: none;

		.product-order-header {
			width: 100%;
			margin-bottom: 24px;

			ul {
				display: flex;
				width: 100%;

				li {
					font-family: $nunito;
					font-weight: normal;
					color: $black-800;
					font-size: 16px;
					position: relative;
					text-align: center;
					padding-right: 80px;

					&::after {
						content: '';
						color: $purple;
						position: absolute;
						right: 35px;
						top: 4px;
						width: 8px;
						height: 8px;
						border-bottom: 2px solid $purple-light;
						border-right: 2px solid $purple-light;
						transform: rotate(-45deg);
					}

					&:last-child {
						&::after {
							display: none;
						}
					}
				}

				.selected {
					font-family: $nunito;
					font-weight: 800;
					color: $red-500;
				}
			}
		}

		.product-order-step {
			display: flex;
			flex-direction: column;

			.information {
				order: 1;
				padding: 24px;
				background-color: $black-100;
				margin: 24px 0 0;

				.information-header {
					font-family: $nunito;
					font-weight: 700;
					font-size: 16px;
					color: $black-800;
					margin-bottom: 10px;
				}

				.information-content {
					font-family: $nunito;
					font-weight: normal;
					font-size: 14px;
					line-height: 19px;
					color: $black-800;

					p {
						font-weight: 600;

						&:last-child {
							font-weight: 100;
						}
					}
				}
			}

			.order {
				width: 100%;
				padding: 24px 16px;
				// border-radius: 10px;
				background-color: $white;
				box-shadow: $box-shadow;

				.order-header {
					font-family: $nunito;
					font-weight: 700;
					font-size: 16px;
					color: $black-800;
					margin-bottom: 20px;
				}

				.order-content {
					display: flex;
					align-items: center;

					img {
						width: 100%; //FIX IE
					}

					.img {
						flex-basis: 33.33%;
						flex-shrink: 0;
						margin-right: 24px;
					}

					.order-info {
						display: flex;
						flex-direction: column;
						align-self: stretch;
						width: 100%;
						padding-block: 8px;
					}

					.description {
						margin-bottom: 8px;
						font-family: $nunito;
						font-size: 12px;
						font-weight: 600;
						color: $black-600;
						text-transform: uppercase;
					}

					.name {
						font-family: $nunito;
						font-weight: 700;
						font-size: 16px;
						color: $black-800;
						text-transform: uppercase;
						// flex-basis: 33.33%;
						flex: 1;
					}

					.points {
						font-family: $nunito;
						font-size: 16px;
						color: $black-800;
						// flex-grow: 1;
						// text-align: right;
					}

					.quantity {
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-top: 1px solid $black-100;
						margin-top: 16px;
						padding-top: 24px;

						&-picker {
							display: flex;
							align-items: center;
							gap: 15px;
						}

						&-text {
							font-size: 12px;
							line-height: 16px;
							color: $black-600;
						}

						&-points {
							color: initial;
						}

						button {
							display: flex;
							justify-content: center;
							align-items: center;
							background: $red-500;
							color: $white;
							width: 32px;
							height: 32px;
							font-size: 31px;

							&[disabled] {
								background: $black-100;
							}
						}
					}
				}
			}

			.address {
				width: 100%;
				padding: 24px 16px;
				// border-radius: 10px;
				background-color: $white;
				box-shadow: $box-shadow;

				.address-header {
					font-family: $nunito;
					font-weight: 700;
					font-size: 16px;
					color: $black-800;
					margin-bottom: 20px;
				}

				.address-wrapper {
					font-family: $nunito;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					color: $black-800;

					.address-content {
						width: 100%;

						div {
							flex: 100%;
							margin-bottom: 10px;

							&:last-of-type {
								margin-bottom: 0;
							}

							p {
								font-size: 12px;
								font-weight: 600;
								margin-bottom: 16px;
								color: $black-600;

								&:last-of-type {
									margin-bottom: 0;
								}

								span {
									display: block;
									margin-top: 4px;
									font-size: 16px;
									font-weight: 700;
									color: $black-800;
								}
							}
						}

						.digital-product {
							margin-top: 14px;

							div:not(.checkboxes) {
								display: grid;
								grid-template-columns: 1fr auto;
								grid-template-rows: 1fr 1fr;
							}

							p {
								grid-column: 1 / 1;
								grid-row: 1 / 1;
							}

							.value {
								font-weight: 700;
								grid-column: 1 / 1;
								grid-row: 2 / 2;
							}

							p:not(.digital-notification) {
								margin-bottom: 8px;
							}

							input {
								grid-column: 1 / span 2;
								grid-row: 2 / 2;
								border: 1px solid $black-600;
								padding: 8px 16px;
								width: 100%;
								color: $black-800;
							}

							.digital-notification {
								margin-top: 32px;
								color: $black-800;
							}
						}

						.cashback-product {
							margin-top: 14px;
						}

						.edit-btn {
							grid-column: 2 / span 1;
							grid-row: 1 / span 2;
							align-self: center;
							padding: 8px 16px;
							background: $black-100;
							width: fit-content;
							height: fit-content;
							color: $red-500;
							font-weight: 700;
							cursor: pointer;

							.edit-btn-icon {
								display: inline-block;
								margin-right: 8px;
							}
						}
					}

					.checkboxes {
						width: 100%;
						margin-block: 36px 12px;

						.checkboxes-wrapper {
							width: 100%;
							padding-left: 40px;

							input[type="checkbox"] {
								display: none;

								&:checked {
									+label {
										&::before {
											content: url('../img/icons/ico-check.svg');
										}
									}
								}

								+label {
									position: relative;
									vertical-align: middle;
									line-height: 19px;
									font-family: $nunito;
									font-size: 16px;

									&::before {
										content: '';
										background: $black-100;
										// border: 1px solid $black-800;
										border-radius: 50%;
										color: $purple;
										font-size: 24px;
										position: absolute;
										left: -40px;
										top: 50%;
										height: 32px;
										width: 32px;
										display: flex;
										align-items: center;
										justify-content: center;
										transform:translateY(-50%);
									}
								}
							}
						}
					}
				}
			}

			.confirmation {
				width: 100%;
				padding: 24px 16px;
				border-radius: 10px;
				background-color: $white;
				box-shadow: $box-shadow;

				.confirmation-header {
					font-family: $nunito;
					font-weight: 700;
					font-size: 16px;
					color: $black-800;
					margin-bottom: 20px;
				}

				.confirmation-wrapper {
					font-family: $nunito;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					color: $purple;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					// div {
					// 	flex: 1;
					// 	display: flex;
					// }

					.confirmation-image {
						flex-basis: calc(33.33% - 24px);
						margin-right: 24px;
						padding-right: 10px;
						img {
							align-self: center; //FIX chrome
							width: 100%; //FIX IE
						}
					}

					.confirmation-product {
						display: flex;
						flex-basis: 66.66%;
						flex-direction: column;
						align-self: stretch;
						padding-block: 8px;
					}

					.confirmation-description {
						margin-bottom: 8px;
						font-family: $nunito;
						font-size: 12px;
						font-weight: 600;
						color: $black-600;
						text-transform: uppercase;
					}

					.confirmation-name {
						font-family: $nunito;
						font-weight: normal;
						font-size: 16px;
						font-weight: 700;
						color: $black-800;
						text-transform: uppercase;
						padding-right: 20px;
						flex: 1;
					}

					.confirmation-data {
						border-top: 1px solid $black-200;
						padding-top: 24px;
						margin-top: 24px;
						width: 100%;
						font-family: $nunito;
						font-weight: normal;
						font-size: 16px;
						flex-direction: column;

						p {
							margin-bottom: 16px;
							font-size: 12px;
							font-weight: 600;
							color: $black-600;

							&:last-of-type {
								margin-bottom: 0;
							}

							span {
								display: block;
								margin-top: 4px;
								font-size: 16px;
								font-weight: 700;
								color: $black-800;
							}
						}
					}

					.confirmation-digital {
						margin-top: 16px;

						.confirmation-code {
							margin-top: 16px;
						}

						input {
							border: 1px solid $black-600;
							padding: 8px 16px;
							width: 100%;
							color: $black-800;
						}

						.resend-code {
							margin-top: 8px;
							color: $black-800;

							&-button {
								color: $black-800;

								&:hover {
									color: $red-500;
								}
							}
						}
					}

					.confirmation-cashback {
						margin-top: 16px;
					}
				}
			}

			.message-success {
				display: none;
				text-align: center;

				.message-success-wrapper {
					box-shadow: $box-shadow;
					padding: 32px;
					display: flex;
					// height: 300px;
					align-items: center;
					justify-content: center;

					p {
						font-family: $nunito;
						font-weight: normal;
						font-size: 16px;
						color: $black-800;
						line-height: 19px;
						text-align: center;
						// width: 50%;
						font-weight: 700;

						span {
							font-weight: 100;
						}
					}
				}

				.back-to-catalog {
					display: inline-block;
					font-family: $nunito;
					font-weight: 700;
					background: $red-500;
					// border-radius: 50px;
					color: $white;
					font-size: 16px;
					line-height: 18px;
					padding: 16px 50px;
					cursor: pointer;
					text-decoration: none;
					text-transform: uppercase;
					margin: 24px 0;
				}
			}
		}

		.product-order-btns {
			margin-top: 24px;
			text-align: center;

			.prev {
				display: inline-block;
				font-family: $nunito;
				font-weight: 700;
				background: $black-800;
				// border-radius: 50px;
				color: $white;
				font-size: 16px;
				line-height: 18px;
				padding: 15px 50px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 10px;
				display: none;
			}

			.next {
				display: inline-block;
				font-family: $nunito;
				font-weight: 700;
				background: $red-500;
				// border-radius: 50px;
				color: $white;
				font-size: 16px;
				line-height: 18px;
				padding: 16px 50px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 10px;
			}
		}
	}
}
