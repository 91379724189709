@media screen and (max-width: 1200px) {
	.header {
		max-width: 90%;
		min-height: 90px;
		padding: 15px;

		.header-wrapper {
			.logo {
				position: absolute;
				top: 20px;
				width: 100%;
				margin-top: 0;
				display: inline-block;
				float: none;
				text-align: center;
			}

			.menu {
				display: none;
			}

			.menu-trigger {
				display: block;
			}

			.cc-logo {
				top: 8px;
				text-align: right;

				img {
					width: 78px;
					height: auto;
				}
			}
		}
	}

	.wrapper {
		margin: 0 auto;
		max-width: 90%;
		padding: 0;
	}

	.slider {
		display: block;

		.user-data {
			width: 100%;
		}

		.slide {
			.slide-container {
				height: auto;

				img {
					height: auto;
					width: 100%
				}
			}
		}
	}

	.user-prizes .movements {
		display: none;
	}

	.program {
		.step {
			.column {
				width: 100% !important;
				float: none !important;
			}
		}
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				background-position: left center;
			}
		}
	}

	// .featured-prizes {
	// 	.prizes {
	// 		.item {
	// 			width: 50%;
	// 		}
	// 	}
	// }

	.products,
	.experiences {
		h2 {
			width: 50%;
			float: left;
		}

		.search-container {
			.filters-bar {
				display: none !important;
			}
		}

		.main {
			width: 100%;
			padding: 0;

			.highlights {
				.img-block {
					background-position: left center;

					.text-container {
						h2 {
							float: none;
							width: auto;
						}
					}
				}

				// .products-block {
				// 	.product {
				// 		padding: 30px;
				// 		width: 50%;
				// 	}
				// }
			}

			.table {
				.product-list {
					.item {
						width: 100%;
						float: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		width: 100%;
	}

	.header {
		padding: 10px 20px;
		width: 100%;
	}

	.last-contest-info {
		.contest-info {
			.item {
				width: 50%;
				padding: 30px;
			}
		}
	}

	.warning {
		.container {
			width: 70%;
		}
	}

	.movements {
		display: none;
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				.content-left {
					width: 50%;
				}

				h2 {
					font-size: 24px;
				}
			}
		}
	}

	.hero-cta {
		@include padding($short: 40px);
		&__title {
			font-size: 31px;

			&--big {
				font-size: 46px;
			}
		}
		&__text {
			&:last-of-type {
				@include margin($bottom: 30px);
			}
			&--small {
				font-size: 16px;
				line-height: 20px;
			}
			&--medium {
				font-size: 20px;
				line-height: 25px;
			}
			&--big {
				font-size: 30px;
				line-height: 34px;
			}
		}
	}
	.btn {
		@include padding($short: 12px 25px);
		font-size: 12px;
	}
	.title-cta {
		display: block;
		&__column {
			@include size(100%, auto);
			@include margin($bottom: 20px);
			@include padding($short: 40px);
		}
		&__title {
			font-size: 26px;
			@include margin($bottom: 40px);
			&::after {
				@include size(80px, 14px);
				bottom: -20px;
			}
		}
		&__subtitle {
			font-size: 20px;
			@include margin($bottom: 20px);
		}

		&__text {
			font-size: 16px;
			@include margin($bottom: 30px);
		}
	}
}

@media screen and (max-width: 600px) {
	.address {
		.address-wrapper {
			.address-content {
				display: block;
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.title-cta {
		&__column {
			@include padding($short: 40px 20px);
		}
	}
	.hero-cta {
		@include padding($short: 40px 20px);
	}
}

@media screen and (max-width: 550px) {
	.product-order {
		.product-order-header {
			ul {
				display: block !important;

				li {
					text-align: left !important;
					display: block;
					padding: 5px 10px 5px !important;

					&::after {
						content: '' !important;
					}
				}
			}
		}

		.product-order-btns {
			a {
				display: block !important;
				margin: 5px 0 !important;
			}
		}
	}

	.confirmation {
		.confirmation-wrapper {
			display: block !important;
			width: 100%;
			height: auto !important;

			.confirmation-image {
				display: block !important;
				flex: none !important;
				height: 150px;
			}

			.confirmation-name {
				display: block !important;
				flex: none !important;
				margin-top: 25px;
			}

			.confirmation-data {
				display: block !important;
				flex: none !important;
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.video {
		.video-container {
			height: 110px;
		}
	}

	.small-banner {
		height: 110px;

		p {
			font-size: 12px;
			line-height: 18px;
			padding: 20px;
		}
	}

	.featured-prizes {
		.prizes {
			.item {
				width: 100%;
				padding: 30px;
				float: none;
			}
		}
	}

	.last-contest-info {
		margin-bottom: 35%;

		.contest-info {
			.item {
				width: 100%;
				padding: 30px;
				float: none;
				text-align: center;
			}
		}
	}

	.user-data {
		.table-user-data {
			.input-wrapper {
				width: 100%;
				float: none;

				&:nth-child(7) {
					width: 100%;
				}
			}
		}
	}

	.login {
		.login-wrapper {
			>div {
				width: 90%;
				margin: 20px auto 20px;
			}
		}
	}

	.tableResponsive {
		th {
			width: 20%;
		}

		td {
			width: 80%;
		}
	}

	.section {
		>div {
			h2 {
				text-align: center;
			}
		}
	}

	.program {
		.container {
			.box {
				text-align: center;
			}

			.table-container {
				table:first-child {
					display: none;
				}
			}
		}
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				.content-left {
					width: 100%;
				}

				h2 {
					position: relative;
					bottom: 0;
					right: 0;
					top: 25px;
				}
			}
		}
	}

	.products,
	.experiences {
		h2 {
			width: 100%;
			float: none;
		}

		aside {
			width: 100%;
			float: none;
			padding: 0;

			.menu {
				.head {
					h2 {
						text-align: left;
						font-size: 11px;
					}

					ul {
						li {
							a {
								font-size: 10px;
							}
						}
					}
				}
			}
		}

		.filters-bar-responsive {
			margin-bottom: 25px;
		}

		.main {
			.highlights {
				.img-block {
					.text-container {
						position: relative;
						text-align: center;
						width: 100%;

						h2 {
							width: 100%;
							text-align: center;
						}

						p {
							padding: 3px;
						}
					}
				}

				.products-block {
					.product {
						width: 100%;
					}
				}
			}

			.table {
				.table-header {
					.breadcrumbs {
						display: block;
						width: 100%;
					}

					.favorites-widget {
						display: block;
						float: none;
						margin-top: 15px;
						margin-bottom: 15px;
					}

					.paginator {
						span {
							&:first-child {
								display: block;
							}
						}
					}
				}
			}

			.product-detail {
				.product-detail-header {
					height: auto;

					.favorites-widget {
						display: block;
						float: none;
						margin-top: 10px;
					}
				}

				.product-detail-container {
					min-height: 500px;

					.product-detail-image {
						width: 100%;
						padding-right: 0;

						img {
							height: 150px;
						}
					}

					.product-detail-description {
						width: 100%;
						display: block;
						order: 0;
					}

					.product-detail-data {
						width: 100%;
						order: 1;

						.data-wrapper {
							padding: 20px;
							position: static;
						}
					}
				}
			}

			.product-order {
				.product-order-step {
					.order {
						.order-content {
							flex: none;
							display: block;
							width: 100%;
							height: auto;

							.img {
								display: block;
								flex: none;
								height: 150px;
							}

							.name {
								display: block;
								flex: none;
								margin-top: 25px;
							}

							.points {
								display: block;
								flex: none;
								margin-top: 15px;
								margin-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}

	.contest {
		p {
			font-size: 12px;
			line-height: 18px;
		}

		.upload-wrapper {
			div {
				width: 100% !important;
				float: none !important;
				text-align: left !important;

				label {
					font-size: 12px !important;

					&::before {
						vertical-align: middle;
					}
				}

				a {
					font-size: 12px;

					&::before {
						vertical-align: middle;
					}
				}

				&:first-child {
					margin-bottom: 15px;
				}
			}
		}
	}

	.footer {

		.footer-wrapper ul {
			width: 100%;
			float: none;
			text-align: center !important;

			li {
				display: block;
				margin-bottom: 10px;
			}
		}
	}
}
