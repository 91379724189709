.small-banner {
    height: 150px;
    position: relative;
    display: block;
    width: 100%;
    background-image: url('/assets/img/general/small_banner.png');
    background-size: cover;
    background-position: top center;
    p {
        display: block;
        width: 100%;
        color: $white;
        padding: 50px 25px;
        font-size: 24px;
        text-transform: uppercase;
    }
}