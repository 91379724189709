.privacy {
    padding: 30px 30px 40px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
	border-radius: 20px;
    
	h2 {
        font-family: $nunito;
        font-weight: 700;
        color: $purple;
        font-size: 31px;    
        padding: 10px 0 20px;
    }
    p {
        font-family: $nunito;
        font-weight: normal;
        font-size: 16px;
        color: $purple;
        line-height: 21px;
    }
}