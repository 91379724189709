.footer {
	background: #5a5a5a;
	position: absolute;
	width: 100%;
	padding: 26px;
	margin-top: 15px;
	bottom: -115px;
	left: 0;

	.footer-wrapper {
		position: relative;
		width: 100%;
		display: block;
		max-width: 1140px;
		margin: 0 auto;
		height: 100%;

		ul {
			position: relative;
			width: 50%;
			display: block;
			margin: 0 auto;
			height: 100%;
			float: left;

			li {
				position: relative;
				display: inline-block;
				margin: 0 20px;

				a {
					color: $white;
					font-size: 12px;
					text-decoration: none;
				}
			}

			&:last-child {
				text-align: right;
			}
		}
	}
}