.user-data {
    padding: 35px 40px 40px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
	// border-radius: 20px;
    h2 {
        font-family: $nunito;
        font-weight: 800;
        color: $black-800;
        font-size: 31px;    
        padding: 10px 0 20px;
    }
    p {
        font-family: $nunito;
        font-weight: normal;
		color: $black-800;
        font-size: 16px;
        line-height: 1.2;
        padding: 0 0 20px;
    }
    .table-user-data {
        overflow: hidden;
        position: relative;
        display: flex;
		flex-wrap: wrap;
        .input-wrapper {
            flex-basis: 100%;
			margin-bottom: 10px;

			@media screen and (min-width: 1200px) {
				flex-basis: calc(25% - 10px);
				margin-right: 10px;
			}

			&:first-of-type,
			&:nth-of-type(2) {
				@media screen and (min-width: 1200px) {
					flex-basis: calc(50% - 10px);
				}
			}

			&:nth-of-type(6n+7) {
				@media screen and (min-width: 1200px) {
					flex-basis: calc(75% - 10px);
				}
			}

            .container {
                background: $white;
                // border-radius: 10px;
                width: 100%;
				color: $black-800;
				padding: 15px;
				display: flex;
				flex-direction: column;
				border: 1px solid $purple-lighter;
				min-height: 70px;
                label {
                    font-family: $nunito;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    vertical-align: top;
                    width: 100%;
                    margin: 0;
                    overflow: hidden;
                    padding: 0;
                    white-space: nowrap;
					margin-bottom: 5px;
                }
                input {
                    font-family: $nunito;
					color: $black-800;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    background: none;
                    border: 0;
                    padding: 0;
                    margin: 0 4px 0;
                    width: 100%;
                    display: table-cell;
                    margin: 0;
                    overflow: hidden;
					padding: 0;
					vertical-align: top;
                }
                textarea {
                    max-height: 160px;
                    overflow-x: hidden;
                    overflow-wrap: break-word;
                    resize: none;
                    height: 48px;
                    width: 100%;
                    font-family: $nunito;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    background: none;
                    border: 0;
                    padding: 0;
                    margin: 0 4px 0;
                }
                &.disabled {
                    background: $black-100;
                }
            }
			// &:first-child,
			// &:nth-of-type(6n+7) {
			// 	width: 75%;
			// }
        }
        .observations {
            display: none;
        }
    }
    .btn {
        padding: 0;
        text-align: center;
        margin-top: 20px;
        a {
            font-family: $nunito;
            font-weight: 700;
            background: $red-500;
            border: 0;
            // border-radius: 50px;
            color: $white;
            font-size: 16px;
			line-height: 18px;
            padding: 17px 45px;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            // margin: 0 10px;
        }
    }
    .checkboxes {
        margin: 20px 0 10px;
        display: none;
        .checkboxes-wrapper {
            display: inline-block;
            text-align: left;
            input[type="radio"] {
                display: none;
            }
            input[type="radio"] + label {
                background: $white;
                border: 1px solid $purple;
                display: inline-block;
                height: 20px;
				border-radius: 5px;
                margin-bottom: 3px;
                position: relative;
                vertical-align: middle;
                width: 20px;
                &::before {
                    content: ' ';
                    font-size: 20px;
                    position: absolute;
                    left: 3px;
                    top: 0;
                }
            }
            input[type="radio"]:checked + label {
                &::before {
                    content: 'X';
                }
            }
            label {
                font-family: $nunito;
				color: $purple;
                font-weight: normal;
                font-size: 14px;
            }
        }
    }
    .confirm-panel {
        margin-top: 20px;
        text-align: center;
        display: none;
        a {
			display: inline-block;
            font-family: $nunito;
            font-weight: 700;
            background: $bordeaux;
            border: 0;
            border-radius: 50px;
            color: $white;
            font-size: 16px;
			line-height: 18px;
            padding: 17px 45px;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0 10px;

			+ a {
				background: $purple;
			}
        }
    }
}
