.header {
    min-height: 90px;
    position: relative;
    width: 100%;
    z-index: 2;
    padding: 15px;
    background: #E1DFE6;
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    .header-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        .logo {
            position: relative;
            width: 25%;
            display: block;
            float: left;
            text-align: center;
            margin-top: 16px;
            a {
                img {
                    height: 35px;
                }
            }
        }
        .menu {
            position: relative;
            width: 60%;
            display: block;
            float: left;
            top: 15px;
			font-family: $nunito;
            
			ul {
                align-items: center;
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    align-items: center;
                    border-left: 1px solid #5a5a5a;
                    display: flex;
                    margin: 0;
					position: relative;

                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        padding: 3px 10px;
                        line-height: 18px;
                        color: #5a5a5a;
                        text-decoration: none;
                        font-size: 15px;
                        &:hover {
                            color: red;
                        }
                    }
                    &:first-child {
                        border-left: 0;
                        a {
                            color: red;
                        }
                    }
                    .submenu {
                        display: none;
                        left: 0;
                        padding: 10px 0;
                        position: absolute;
                        top: 100%;
						width: 100%;
						background: #e1dfe6;
						border-radius: 0 0 10px 10px;
                        li {                           
                            text-align: center;
                            width: 100%;
                            border-left: none;
                            a {
                                padding: 6px 10px;
                                font-family: $nunito;
                                font-weight: 500;
                                font-size: 12px;
								color: #5a5a5a;
                                &:hover {
                                    color: red;
                                }
                            }
                            &:last-child {
                                a {
                                    &::before {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        &:hover {
                            .submenu {
                                display: block;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            &::before {
                                font-family: "Font Awesome 5 Free";
                                content: "\f2f5";
                                margin-right: 5px;
                            }
                        }
                    }

                }
            }
        }
        .cc-logo {
            position: relative;
            width: 15%;
            display: block;
            float: right;
            text-align: center;
			margin-top: 10px;
        }
        .menu-trigger {
            display: none;
            float: left;
            width: 52px;
            height: 52px;
            position: absolute;
            top: 8px;
            background-color: $red-500;
			border-radius: 50%;

            ul {
				margin-top: 4px;
				width: 52px;
				height: 48px;
				padding: 10px;

                li {
                    background-color:$white;
                    position: relative;
                    width: 100%;
                    height: 4px;
                    margin-bottom: 6px;
					transition: .4s;

					&:last-of-type {
						display: none;
					}
                }
                .change {
                    &:first-child {
                        opacity: 0;
                    }
                    &:nth-child(2) {
                        transform: rotate(-45deg) translate(0, 0);
                    }
                    &:nth-child(3) {
                        transform: rotate(45deg) translate(-7px, -7px);
                    }
                    &:last-child {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .responsive-menu {
        display: none;
        position: relative;
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: 60px;
        ul {
            li {
                a {
                    color: #252525;
                    text-decoration: none;
                    display: inline-block;
                    height: 100%;
                    line-height: 18px;
                    padding: 16px 0;
                    width: 100%;
                    &:hover {
                        color: $red;
                    }
                }
                ul {
                    li {
                        a {
                            font-weight: 500;
                            font-size: 14px;
                            padding: 0 0 10px;
                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
    .active {
        display: block;
    }
}
