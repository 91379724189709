.user-points {
	padding: 35px 40px 40px;
	overflow: hidden;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;
	// border-radius: 20px;
	
	h2 {
		font-family: $nunito;
		font-weight: 800;
		color: $black-800;
		font-size: 31px;    
		padding: 10px 0 20px;
		// display: inline-block;
		margin-bottom: 32px;
	}
	
	.points-container {
		border-bottom: 1px solid $black-200;
		margin-bottom:40px;
		padding-bottom: 8px;
		font-size: 16px;
		color: $black-800;
		
		.points {
			display: block;
			margin-top: 8px;
			font-size: 31px;
			font-weight: 500;
		}
	}
	.btns {
		margin: 50px 0 10px;
		a {
			background: $white;
			border: 1px solid #8e8e8e;
			border-radius: 10px;
			cursor: pointer;
			margin: 0px 10px;
			padding: 8px 10px 6px;
			transition: background .4s, border .4s, color .4s;
			font-family: "Gotham Book", sans-serif;
			font-weight: normal;
			font-size: 14px;
			color: #878787;
			text-decoration: none;
			&:first-child {
				margin: 0 10px 0 0;
			}
			&:hover {
				background: $bordeaux;
				border-color: $bordeaux;
				color: $white;
			}
		}
		.active {
			background: #8e8e8e;
			color: $white;
		}
	}
	table {
		width: 100%;
		margin-top: 20px;
		thead {
			tr {
				background: $red-500;
				th {
					color: white;
					padding: 10px 16px;
				}
			}
		}
		tbody {
			tr {
				&:nth-child(even) {
					background: rgba(233, 230, 237, .5);;
				}
				&:nth-child(odd) {
					background: white;
				}
				td {
					padding: 10px 16px;
					text-align: center;
					font-family: $nunito;
					font-weight: normal;
					font-size: 16px;
					color: $black-800;
					i {
						cursor: pointer;
					}
				}
			}
		}
	}
	.pagination {
		margin: 20px -10px;
		text-align: center;
		a {
			font-family: $nunito;
			font-weight: 700;
			background: $red-500;
			border: 0;
			// border-radius: 50px;
			color: $white;
			font-size: 16px;
			line-height: 18px;
			padding: 14px 45px;
			cursor: pointer;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			margin: 0 10px;
			&.disabled {
				background: #e0e0e0;
				color: #5a5a5a;
				opacity: 0.55;
			}
		}
	}
	.tableResponsive {
		tbody {
			.odd {
				background-color: rgba(233, 230, 237, .2);
			}

			.even {
				background-color: rgba(233, 230, 237, .5);
			}
			th {
				text-align: right;
				font-size: 14px;
				color: $red-500;
				font-weight: 700;
				width: 50%;
				padding: 10px 16px;
			}
			td {
				text-align: left;
				width: 50%;
				padding: 10px 16px;
				font-family: $nunito;
				font-weight: 400;
				font-size: 14px;
				color: $black-800;
			}
		}
	}
}

.prize-status {
	background: rgba(255, 255, 255, 0.8);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
	display: none;
	.status-wrapper {
		background: $white;
		box-shadow: $box-shadow;
		margin: 150px auto 0;
		padding: 20px 10px;
		width: 500px;
		height: 180px;
		svg {
			height: 80px;
			width: 100%;
			padding: 0;
			font: normal 9pt sans-serif;
			line {
				stroke: #ececec;
				stroke-width: 4px;
			}
			circle {
				fill: #8e8e8e;
			}
			text {
				font-family: "Gotham Book", sans-serif;
				font-weight: normal;
				fill: #8e8e8e;
				text-anchor: middle;
			}
		}
		.status-btn {
			position: relative;
			display: block;
			width: 100%;
			text-align: center;
			margin: 30px 0 10px 0;
			.close {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				background: #ed1c24;
				border: 0;
				// border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 10px 0;
			}
		}
	}
}
