.text {
	width: 100%;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	color: $black-600;
	text-decoration: none;

	&--semibold {
		font-weight: 500;
	}

	&--bold {
		font-weight: 700;
	}

	&--white {
		color: $white;
	}

	&--big {
		font-size: 61px;
	}

	&--red {
		color: $red-light;

		.title-lead & {
			font-size: 45px;
			margin-right: 10px;
		}
	}

	&--green {
		color: $green;
	}

	&--orange {
		color: $orange;
	}

	&--gray {
		color: $gray-light
	}

	&--center {
		text-align: center;
	}
}

.title {
	font-size: 31px;
	line-height: 37px;
	color: $black-800;
	font-weight: 500;
	width: 100%;
	@include margin($bottom: 24px);

	&--center {
		text-align: center;

		&.title--underline {
			&::after {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&-lead {
		width: 100%;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		color: $purple;
	}

	&--bold {
		font-weight: 700;
	}

	&--medium {
		font-size: 25px;
		line-height: 30px;
	}

	&--small {
		font-size: 16px;
		line-height: 22px;
	}

	&--big {
		font-size: 40px;
		line-height: 48px;
	}

	&--white {
		color: $white;
	}

	&--underline {
		@include relative;
		@include margin($bottom: 40px);

		&::after {
			content: '';
			@include absolute(bottom -20px left 0);
			@include size(80px, 14px);
			background: url('/assets/img/general/separador.svg') no-repeat center / cover;
		}
	}
}

.margin {
	&--bottom {
		@include margin($bottom: 40px);

		&-small {
			@include margin($bottom: 10px);
		}

		&-null {
			@include margin($bottom: 0);
		}
	}
}
