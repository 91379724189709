.user-data-form {
    padding: 35px 40px 40px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
	// border-radius: 20px;
    h2 {
        font-family: $nunito;
        font-weight: 800;
        color: $black-800;
        font-size: 31px;    
        padding: 10px 0 20px;
    }
    p {
        font-family: $nunito;
        font-weight: normal;
		color: $black-800;
        font-size: 16px;
        line-height: 1.2;
        padding: 0 0 20px;
    }
    .table-user-data {
        overflow: hidden;
        position: relative;
        display: flex;
		flex-wrap: wrap;
        .input-wrapper {
            flex-basis: 100%;
			margin-bottom: 10px;

			@media screen and (min-width: 1200px) {
				flex-basis: calc(25% - 10px);
				margin-right: 10px;
			}

			&:first-of-type,
			&:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
				@media screen and (min-width: 1200px) {
					flex-basis: calc(50% - 10px);
				}
			}

			// &:nth-of-type(6n+7) {
			// 	@media screen and (min-width: 1200px) {
			// 		flex-basis: calc(75% - 10px);
			// 	}
			// }

            .container {
                background: $white;
                // border-radius: 10px;
                width: 100%;
				color: $black-800;
				padding: 15px;
				display: flex;
				flex-direction: column;
				border: 1px solid $purple-lighter;
				min-height: 70px;

                &.w-error {
                    border-color: $red-500
                }
                
                label {
                    font-family: $nunito;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    vertical-align: top;
                    width: 100%;
                    margin: 0;
                    overflow: hidden;
                    padding: 0;
                    white-space: nowrap;
					margin-bottom: 5px;
                }
                input {
                    font-family: $nunito;
					color: $black-800;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    background: none;
                    border: 0;
                    padding: 0;
                    margin: 0 4px 0;
                    width: 100%;
                    display: table-cell;
                    margin: 0;
                    overflow: hidden;
					padding: 0;
					vertical-align: top;
                }
                textarea {
                    max-height: 160px;
                    overflow-x: hidden;
                    overflow-wrap: break-word;
                    resize: none;
                    height: 48px;
                    width: 100%;
                    font-family: $nunito;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    background: none;
                    border: 0;
                    padding: 0;
                    margin: 0 4px 0;
                }
                &.disabled {
                    background: $black-100;
                }
            }
			// &:first-child,
			// &:nth-of-type(6n+7) {
			// 	width: 75%;
			// }
        }
        .observations {
            display: none;
        }
    }
    .btn {
        padding: 0;
        text-align: center;
        margin-top: 20px;

        .submit-btn {
            font-family: $nunito;
            font-weight: 700;
            background: $red-500;
            border: 0;
            // border-radius: 50px;
            color: $white;
            font-size: 16px;
			line-height: 18px;
            padding: 17px 45px;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            // margin: 0 10px;

            &:disabled {
                background: $grey-500;
                cursor: not-allowed;
            }
        }
    }

    .mandatory-fields-text {
        font-size: 12px;
        line-height: 16px;
        color: $black-800;
    }

    .error-container {
        margin-top: 10px;

        .error-text {
            font-size: 12px;
            line-height: 16px;
            color: $red-500;
            padding: 0;
        }
    }

    .checkboxes {
        margin: 20px 0 10px;
        display: flex;
        flex-wrap: wrap;
        // display: none;
        .checkboxes-wrapper {
            display: block;
            text-align: left;
            flex-basis: 100%;
            margin-bottom: 10px;
            
            @media screen and (min-width: 1200px) {
                flex-basis: calc(50% - 10px);
                margin-right: 10px
            }
            
            .container {
                border: 1px solid $purple-lighter;
                padding: 12px 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                height: 100%;

                &.w-error {
                    border-color: $red-500;
                }
            }

            input[type="checkbox"] {
                position: relative;
                appearance: none;
                display: flex;
                align-content: center;
                justify-content: center;
                font-size: 24px;
                padding: 0.1rem;
                border: 1px solid #0075FF;
                cursor: pointer;

                &:checked {
                    background: #0075FF;
                    
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 24px;
                        height: 24px;
                        -webkit-mask-image: url('../img/icons/ico-check.svg');
                        mask: url('../img/icons/ico-check.svg') center no-repeat;
                        mask-size: 100%;
                        background: white;
                    }
                }

                &::before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    // background-color: green;
                }
            }

            input[type="checkbox"] + label {
                background: $white;
                // border: 1px solid  $black-800;
                // display: inline-block;
                // height: 20px;
				border-radius: 5px;
                // margin-bottom: 3px;
                position: relative;
                vertical-align: middle;
                // width: 20px;

                &::before {
                    content: ' ';
                    font-size: 16px;
                    position: absolute;
                    left: 3px;
                    top: 0;
                }
            }
            // input[type="checkbox"]:checked + label {
            //     &::before {
            //         content: 'X';
            //     }
            // }
            label {
                font-family: $nunito;
				color: $black-800;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
            }
        }
    }

    .response {
        display: none;
        margin-top: 10px;
        width: 100%;

        &.success,
        &.error {
            display: block !important;
        }

        &.success {
            .response-text--success {
                display: block !important
            }
        }

        &.error {
            .response-text--error {
                display: block !important
            }
        }

        &-text {
            text-align: center;
            display: none;

            &--success {
                color: $green;
            }

            &--error {
                color: $red-500;
            }
        }
    }
}
