.container {
    &-link {
        font-weight: 500;
        color: white;
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
        text-decoration: none;
        background: rgba(0, 0, 0, 0.5);
        border: 0;
        border-radius: 50px;
        text-decoration: none;
        font-size: 12px;
        padding: 6px;

        @media (min-width: 768px) {
            right: 20px;
            bottom: 20px;
            font-size: 16px;
            padding: 10px;
        }
    }
}
.btn {
	display: inline-flex;
	@include padding($short: 17px 45px);
	@include margin($right: 20px);
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	// border-radius: 25px;
	background-color: $red-500;
	text-decoration: none;

	&:last-of-type {
		@include margin($right: 0);
	}

	&--white {
		color: $red;
		background-color: white;
	}

	&--gray {
		background-color: $gray;
	}

	&--purple {
		background-color: $purple;
	}

	&--bordeaux {
		background-color: $bordeaux;
	}
	
	&--center {
		margin: 0 auto!important;
	}

}
