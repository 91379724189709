.contest {
    padding: 30px 30px 40px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
    margin-bottom: 25px;
	border-radius: 20px;
	font-family: $nunito;

    h2 {
        font-weight: 700;
        color: $purple;
        font-size: 31px;    
        padding: 10px 0 20px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
		color: $purple;
    }
    .steps {
        margin: 15px 0;
        p {
            line-height: 18px;
            color: $bordeaux;
        }
    }
    .upload-wrapper {
        background-color: #8e8e8e;
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        margin-top: 40px;
        padding: 12px;
        div {
            &:first-child {
                position: relative;
                display: block;
                width: 50%;
                float: left;
                input {
                    display: none;
                }
                label {
                    color: $white;
                    font-size: 14px;
                    &::before {
                        content: '\f574';
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        font-size: 20px;
                        color: $white;
                        margin-right: 10px;
                    }
                }
            }
            &:nth-child(2) {
                position: relative;
                display: block;
                width: 50%;
                float: left;
                text-align: right;
                .filter {
                    text-decoration: none;
                    color: $white;
                    &::before {
                        content: '\f160';
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        font-size: 20px;
                        color: $white;
                        margin-right: 10px;
                    }
                    &.up {
                        &::before {
                            content: '\f161' !important;
                        }
                    }
                }
            }
        }
    }
}