.user-prizes {
	padding: 35px 40px 40px;
	overflow: hidden;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;
	// border-radius: 20px;

	h2 {
		font-family: $nunito;
		font-weight: 800;
		color: $black-800;
		font-size: 31px;
		padding: 10px 0 20px;
	}

	.movements {
		width: 100%;
		margin-top: 20px;
		margin-top: 20px;

		thead {
			tr {
				background: $red-500;

				th {
					color: white;
					padding: 10px 16px;
					font-size: 16px;
					vertical-align: middle;
				}
			}
		}

		tbody {
			tr {
				&:nth-child(even) {
					background: rgba(233, 230, 237, .5);
				}

				&:nth-child(odd) {
					background: white;
				}

				td {
					padding: 10px 16px;
					text-align: center;
					font-family: $nunito;
					font-weight: normal;
					font-size: 16px;
					color: $black-800;
					vertical-align: middle;

					p {
						cursor: pointer;

						i {
							color: $red-500;
						}
					}
				}
			}
		}
	}

	.pagination {
		margin: 20px -10px;
		text-align: center;

		a {
			font-family: $nunito;
			font-weight: 700;
			background: $red-500;
			border: 0;
			// border-radius: 50px;
			color: $white;
			font-size: 16px;
			line-height: 18px;
			padding: 14px 45px;
			cursor: pointer;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			margin: 0 10px;

			&.disabled {
				background: #e0e0e0;
				color: #5a5a5a;
				opacity: 0.55;
			}
		}
	}

	.tableResponsive {
		width: 100%;

		tbody {
			.odd {
				background-color: rgba(233, 230, 237, .2);
			}

			.even {
				background-color: rgba(233, 230, 237, .5);
			}

			th {
				text-align: right;
				font-size: 14px;
				color: $red-500;
				font-weight: 700;
				width: 50%;
				padding: 10px 16px;
			}

			td {
				text-align: left;
				width: 50%;
				padding: 10px 16px;
				font-family: $nunito;
				font-weight: 400;
				font-size: 14px;
				color: $black-800;

				p {
					cursor: pointer;

					i {
						color: $red-500;
					}
				}
			}
		}
	}
}

.prize-status {
	background: rgba(255, 255, 255, 0.8);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
	display: none;

	.status-wrapper {
		background: $white;
		box-shadow: $box-shadow;
		margin: 150px auto 0;
		padding: 20px 10px;
		width: 500px;
		height: 180px;
		// border-radius: 20px;

		svg {
			height: 80px;
			width: 100%;
			padding: 0;
			font: normal 9pt sans-serif;

			line {
				stroke: #ececec;
				stroke-width: 4px;
			}

			circle {
				fill: #8e8e8e;
			}

			text {
				font-family: $nunito;
				font-weight: normal;
				fill: $black-800;
				text-anchor: middle;
			}
		}

		.active {
			circle {
				fill: $red-500;
			}

			text {
				font-family: $nunito;
				font-weight: 500;
				fill: $red-500;
			}


			
			line {
				stroke: $bordeaux;
			}

			&.first, &+.last {
				line {
					stroke: #ececec;
				}
			}
		}

		.status-btn {
			position: relative;
			display: block;
			width: 100%;
			text-align: center;
			margin: 30px 0 10px 0;

			.close {
				font-family: $nunito;
				font-weight: 700;
				background: $red-500;
				border: 0;
				// border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 10px 0;
			}
		}
	}
}
