.featured-prizes {
	padding: 30px 0;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;
	// border-radius: 20px;

	p {
		color: $black-800;
		text-align: center;
		width: 100%;
	}

	&__title {
		color: $black-800;
		text-align: center;
		width: 100%;
		font-size: 31px;
		font-weight: 800;
		line-height: 40px;
	}

	.prizes {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		flex-wrap: wrap;
		width: 100%;
		padding: 30px 0;

		@media screen and (min-width: 1200px) {
			grid-template-columns: repeat(4, 1fr);
		}

		.item {
			font-family: $nunito;
			font-weight: normal;
			font-size: 12px;
			padding: 0 30px;
			position: relative;
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-decoration: none;
				color: $purple;
				text-align: center;

				img {
					height: 100%;
					max-height: 200px;
					max-width: 200px; //FIX IE
				}

				.name {
					margin-top: 8px;
					color: $black-800;
					font-size: 16px;
					font-weight: 700;
					line-height: 1.2;
					text-transform: uppercase;
				}

				.brand {
					margin-top: 4px;
					color: $black-600;
					font-size: 13px;
					line-height: 1.2;
					text-transform: uppercase;
				}

				.points {
					font-family: $nunito;
					font-weight: 500;
					font-size: 16px;
					margin-top: 8px;
					color: $black-800;
				}
			}
		}
	}
}
