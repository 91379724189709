.last-contest-info {
    padding: 40px 0;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
	border-radius: 20px;

    p {
        color: $purple;
        text-align: center;
        width: 100%;
    }
    .contest-info {
        display: block;
        width: 100%;
        padding: 30px 0;
        .item {
            font-family: $nunito;
            font-weight: normal;
            font-size: 12px;
            display: block;
            float: left;
            padding: 0 30px;
            width: 25%;
            position: relative;
            p {
                color: $purple;
            }
            .title {
                text-transform: uppercase;
                text-align: left;
                margin-bottom: 10px;
            }
            .likes {
                display: block;
                position: relative;
                width: 100%;
                border-bottom: 1px solid #5a5a5a;
                margin-top: 10px;
                padding-bottom: 5px;
                text-align: left;
                &::before {
                    content: '\f004';
                    font-family: "Font Awesome 5 Free";
                    color: #5a5a5a;
                    margin-right: 5px;
                    cursor: pointer;
                }
                span {
                    position: relative;
                    float: right;
                }
            }
            .description {
                position: relative;
                margin-top: 5px;
                text-align: left;
                line-height: 16px;
            }
        }
    }
    .btn {
        display: block;
        width: 100%;
        position: relative;
        text-align: center;
		
        a {
            font-family: $nunito;
            font-weight: 500;
            background: #ed1c24;
            border: 0;
            border-radius: 50px;
            color: $white;
            font-size: 14px;
            padding: 10px 34px 8px;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            margin: 35px 10px 0 10px;
        }
    }
}