*,
*::before,
*::after {
	box-sizing: border-box; //todo mixin
}

html {
	height: 100%;
	min-height: 100%;
	position: relative;
}

body {
	font-family: $nunito;
	font-weight: 300;
	min-height: 100vh;
	width: 100%;
	background-color: #E1DFE6;
	position: relative;
}

.wrapper {
	position: relative;
	display: block;
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;
	height: 100%;

	.section {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 20px;
	}
}

.clear {
	clear: both;
}

//remove default styles
input,
button,
select,
textarea {
	border: 0;
	outline: 0;
	background: transparent;
	@include margin($short: 0);
	@include padding($short: 0);
	font-family: inherit;
}

select {
	&::-ms-expand {
		display: none; //remove IE default arrow
	}

	&::-ms-value {
		background: none;
		color: black; //change color according to project
	}
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

img {
	max-width: 100%;
	vertical-align: middle;

	&[data-object-fit=contain] {
		object-fit: contain;
	}

	&[data-object-fit=cover] {
		object-fit: cover;
	}
}

.page404 {
	padding: 30px 30px 40px;
	overflow: hidden;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;
	border-radius: 20px;

	h2 {
		font-family: $nunito;
		font-weight: 700;
		color: $purple;
		font-size: 31px;
		padding: 10px 0 20px;
	}

	p {
		font-family: $nunito;
		font-weight: normal;
		font-size: 16px;
		color: $black-800;
		line-height: 22px;
	}
}
