.warning {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
    display: none;
	
    .container {
        padding: 10px 20px 20px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 35%;
        -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
        box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
        background-color: $white;
        margin: 0 auto;
        text-align: center;
        margin-top: 150px;
		border-radius: 20px;

        p {
            color: $purple;
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 20px;
        }
        .bold {
            font-weight: 500;
            a {
                color: $bordeaux;
            }
        }
    }
}