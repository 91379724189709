 .wrapper-inner{
     display: flex;
     flex-wrap:wrap;
     background-color:$white; 
     padding: 0px 40px 0px;
	//  border-radius: 20px;
 }

 .campain {
     display: flex;
     justify-content: flex-start;
     flex-flow: row wrap;
 }

 strong {
     font-weight: bold;
 }

 .banner {
     display: flex;
     justify-content: center;
     width: 100%;
     flex-direction: row;
     margin-bottom: 20px;   
 }

 .info p {
     margin-bottom: 60px;
     line-height: 22px;
	 color: $black-800;
 }

 .box-program {
     color: $black-800;
     max-width: 990px;
     margin: 0 auto;
     display: flex;
     justify-content: center;
     flex-flow: row wrap;

     div{
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        
        ul {
            width: 230px;
            max-width: 230px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-flow: row wrap;
   
            img {
                margin-bottom: 30px;
                max-height: 170px;
                //height: 170px;
            }
   
            li {
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
   
                button {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    background-color: $red-500;
                    color: $white;
                    border: none;
                    // border-radius: 5px;
                    height: 30px;
                    width: 125px;
                }
   
                &:nth-child(3) {
                    margin-bottom: 25px;
                }
            }
        }

     }
 }

 .info2 {
     display: flex;
     justify-content: center;
     flex: 1 100%;

     p {
         display: flex;
         justify-content: center;
         margin-bottom: 35px;
         font-size: 14px;
     }
 }

 .steps {
     display: flex;
     flex: 1 100%;
     flex-flow: row wrap;
     justify-content: flex-start;
     margin-bottom: 50px;

     ul {
         display: flex;
         flex-flow: row wrap;
         margin-bottom: 25px;

         li {
             display: flex;
             width: 100%;
             align-items: center;

             span {
                 display: flex;
                 background-color: $red-500;
                 color: $white;
                 border: 0;
                 border-radius: 50%;
                 height: 40px;
                 width: 40px;
                 margin: 20px 20px 20px 0px;
                 font-size: 16px;
                 align-items: center;
                 justify-content: center;
             }
         }
     }
 }

 .campain h2 {
     display: flex;
     color: $black-800;
     font-family: $nunito;
     font-size: 31px;
     margin-top: 45px;
     margin-bottom: 30px;
     font-weight: 800;
 }

 .steps {
	color: $black-800;

     h2 {
         display: flex;
         font-size: 25px;
         margin-top: 30px;
         margin-bottom: 30px;
         font-weight: bold;
     }

     ul li h4 {
         font-weight: bold;
         color: $red-500;
         text-transform: uppercase;
     }
 }

 .btncta{
     margin: 0 auto;

    .btncta__btn{
        margin-bottom: 40px;
        color: $red-500;
        border: none;
        border-radius: 80px;
        height: 40px;
        width: 100%;
        a {
            font-family: $nunito;
            font-weight: 500;
            background: $red-500;
            border: 0;
            border-radius: 50px;
            color: $white;
            font-size: 14px;
            padding: 10px 34px 8px;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            margin: 0 10px;
            white-space: nowrap;
        }
     }

 }
 