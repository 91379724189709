.catalog {
	font-family: $nunito;
    padding: 35px 40px 40px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
	// border-radius: 20px;

    h2 {
        font-family: $nunito;
		font-weight: 800;
		color: $black-800;
		font-size: 31px;
		line-height: 42px;
		margin-bottom: 24px;
		flex-basis: calc(50% - 10px);
		margin: 0 20px 25px 0;
    }
    .catalog-container {
        width: 100%;
        padding: 0;
        a {
            text-decoration: none;
        }
        .products-banner {
            cursor: pointer;
            background-position: center center;
            background-size: cover;
            border: 0;
            height: 190px;
            padding: 0;
            position: relative;
            margin: 0 0 20px;
            width: 100%;
            background-image: url("/assets/img/general/products-banner.jpg");
			// border-radius: 20px;
            .content-left {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                background: rgba(255, 255, 255, 0.7);
                bottom: 0;
                left: 0;
                width: 25%;
                padding: 20px 45px 20px 20px;
                position: absolute;
                text-align: left;
                span {
                    grid-column: 1;
                    background: #5a5a5a;
                    display: inline-block;
                    padding: 3px 3px 4px 3px;
                    margin-right: 4px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 15px;
                    font-weight: 700;
                    color: #ffffff;
                    text-transform: uppercase;
                }
                h3 {
                    grid-column: 2;
                    font-weight: 500;
                    font-size: 14px;
                    color: $black-800;
                }
                div {
                    grid-column: 2;
                    p {
                        font-weight: normal;
                        font-size: 14px;
                        color: $black-800;
                    }

                    &::after {
                        background-color: $red-500;
                        content: ' ';
                        height: 28px;
                        position: absolute;
                        right: 10px;
                        top: calc(50% - 14px);
                        width: 28px;
						border-radius: 50%;
                    }

					&:before {
						content: '';
						width: 10px;
						height: 10px;
						position: absolute;
						top: calc(50% - 5px);
						right: 20px;
						border-bottom: 2px solid $white;
						border-right: 2px solid $white;
						z-index: 1;
						transform: rotate(-45deg);
					}
                }
            }

            h2 {
                font-weight: bold;
                font-size: 30px;
                color: $white;
                text-transform: uppercase;
                position: absolute;
                bottom: 10px;
                right: 20px;
            }
        }

        .experiences-banner {
            cursor: pointer;
            background-position: center center;
            background-size: cover;
            border: 0;
            height: 190px;
            padding: 0;
            position: relative;
            margin: 0 0 20px;
            width: 100%;
            background-image: url("/assets/img/general/experiences-banner.jpg");
			// border-radius: 20px;
            
			.content-left {
                background: rgba(255, 255, 255, 0.7);
                bottom: 0;
                left: 0;
                width: 25%;
                padding: 20px 45px 20px 20px;
                position: absolute;
                text-align: left;

                h3 {
                    font-weight: 500;
                    font-size: 14px;
                    color: $black-800;
                }
                div {
                    p {
                        font-weight: normal;
                        font-size: 14px;
                        color: $black-800;
                    }

                    &::after {
                        background-color: $red-500;
                        content: ' ';
                        height: 28px;
                        position: absolute;
                        right: 10px;
                        top: calc(50% - 14px);
                        width: 28px;
						border-radius: 50%;
                    }

					&:before {
						content: '';
						width: 10px;
						height: 10px;
						position: absolute;
						top: calc(50% - 5px);
						right: 20px;
						border-bottom: 2px solid $white;
						border-right: 2px solid $white;
						z-index: 1;
						transform: rotate(-45deg);
					}
                }
            }
            h2 {
                font-weight: bold;
                font-size: 30px;
                color: $white;
                text-transform: uppercase;
                position: absolute;
                bottom: 10px;
                right: 20px;
           }   
        }
    }
}
