.login {
	background-image: url("/assets/img/general/loginback-2023.jpg");
	background-position: center center;
	background-size: cover;
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;

	.login-wrapper {
		width: 100%;
		height: calc(100% - 100px);
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			background: $white;
			// border-radius: 20px;
			box-shadow: $box-shadow;
			padding: 40px 30px;
			width: 420px;

			.login-header {
				text-align: center;
				width: 100%;
				position: relative;

				img {
					width: 100%;
					max-width: 288px;
				}
			}

			a {
				color: $black-800;
				font-weight: 700;
			}			

			.login-contents {
				margin: 30px 0 20px;

				.title {
					font-family: $nunito;
					font-weight: 700;
					color: $black-800;
					text-align: center;
					margin: 20px 0;
				}

				form {
					position: relative;
					text-align: center;
					width: 100%;

					div {
						background: $white;
						// border-radius: 10px;
						// margin: 10px 4px;
						overflow: hidden;
						// box-shadow: $box-shadow;

						+ div {
							margin-top: 16px;
						}

						input {
							font-family: $nunito;
							font-weight: normal;
							border: 0;
							padding: 16px 15px;
							font-size: 16px;
							width: 100%;
							color: $black-800;
							background: $black-100;
							text-align: center;
						}
					}
					
					p {
						text-align: center;
						margin: 16px 0 32px;
						font-size: 16px;
						color: $black-800;

						input[type="checkbox"] {
							display: none;
						}

						input[type="checkbox"]+label {

							display: inline-block;
							position: relative;
							vertical-align: middle;
							line-height: .75;


							&::before {
								background: $white;
								border: 1px solid #252525;
								content: ' ';
								font-size: 24px;
								position: absolute;
								left: -25px;
								top: -5px;
								height: 20px;
								width: 20px;
							}
						}

						input[type="checkbox"]:checked+label {
							&::before {
								content: 'X';
							}
						}
					}
				}

				.btn {
					text-align: center;
					width: 100%;
					position: relative;
					text-align: center;
					width: 100%;
					position: relative;
					background: none;
					border: none;
					border-radius: 0;
					margin: 0 auto;
					padding: 0;

					.submit {
						font-family: $nunito;
						font-weight: 700;
						background: $red-500;
						border: 0;
						// border-radius: 50px;
						color: $white;
						font-size: 14px;
						padding: 16px 40px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						display: block;
						min-width: 130px;
						margin: 0 auto;

						&[disabled] {
							background: #d5d5d5;
							cursor: default;
						}
					}
				}
			}

			.login-footer {
				text-align: center;
				width: 100%;
				position: relative;
				margin-top: 35px;
			}

			.login-error {
				display: none;
				text-align: center;
				width: 100%;
				position: relative;

				.title {
					font-family: $nunito;
					font-weight: 700;
					text-align: center;
					margin: 20px 0;
					font-size: 16px;
				}

				p {
					font-family: $nunito;
					font-weight: 400;
					color: $purple;
					text-align: center;
					margin: 20px 0;
					font-size: 16px;
					line-height: 20px;
				}

				.btn {
					text-align: center;
					width: 100%;
					position: relative;
					text-align: center;
					width: 100%;
					position: relative;
					background: none;
					border: none;
					border-radius: 0;
					margin: 0 auto;
					padding: 0;
				}
			}
		}
	}
}
