.program {
	padding: 40px 30px;
	overflow: hidden;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;

	&__img {
		display: block;
		margin: auto;
	}

	h2 {
		margin-bottom: 30px;
		color: $black-800;
		font-size: 31px;
		font-weight: 800;

		&:not(:first-child) {
			margin-top: 55px;
		}
	}


	h3 {
		margin-bottom: 16px;
		color: $black-800;
		font-size: 16px;
		font-weight: 800;
		text-align: center;
	}

	p {
		font-size: 16px;
		line-height: 1.2;
		padding: 0 0 20px;
		font-weight: 700;
	}

	.text {
		color: #5a5a5a;
		font-weight: 400;
		margin-bottom: 40px;
		font-family: $nunito;

		&--bold {
			font-weight: 700;
			margin-bottom: 0;
			font-family: $nunito;
		}
	}

	.step {
		h1 {
			color: $red-500;
			font-size: 33px;
			text-align: center;
			width: 100%;
			font-family: $nunito;
			font-weight: bold;
			margin-top: 40px;
			margin-bottom: 20px;
		}

		.container {
			margin-bottom: 15px;
			font-family: $nunito;
			font-weight: bold;

			h2 {
				text-align: center;
			}

			.box {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				span {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 41px;
					height: 41px;
					border-radius: 50px;
					border: 1px solid $white;
					background: $red-500;
					color: $white;
					font-size: 16px;
					text-align: center;
					margin-right: 15px;
				}

				p {
					display: inline-block;
					color: $red-500;
					font-size: 18px;
					text-transform: uppercase;
					padding: 0;
					text-align: left;
				}

				h2 {
					color: $red;
					font-size: 18px;
					text-align: center;
					width: 100%;
					margin-top: 40px;
					margin-bottom: 25px;
					padding: 0;
				}
			}

			.table-container {
				margin-bottom: 15px;

				table {
					margin: 0 auto;
					max-width: 768px;
					display: table;
					width: 100%;
					height: 100%;
					border-collapse: collapse;
					border-spacing: 0;

					thead {
						tr {
							background-color: $red-500;

							th {
								padding: 10px 16px;
								font-family: $nunito;
								font-weight: normal;
								font-size: 16px;
								color: $white;
							}
						}
					}

					tbody {
						tr {
							&:nth-child(even) {
								background: #ececec;
							}

							&:nth-child(odd) {
								background: white;
							}

							td {
								padding: 10px 16px;
								font-family: $nunito;
								font-weight: normal;
								font-size: 14px;
								color: $black-800;
								text-align: center;
							}
						}
					}

					&:last-child {
						border-collapse: inherit;
						padding: 0 10px;
						max-width: 372px;
						display: table;
						background: #ececec;
						font-size: 12px;
						line-height: 27px;
						margin-bottom: 40px;

						tbody {
							tr {
								background: #ececec;
							}
						}
					}
				}

				.tableResponsive {
					tbody {
						.odd {
							background-color: #f7f7f7;
						}

						.even {
							background-color: #efefef;
						}

						th {
							text-align: right;
							font-size: 14px;
							color: #5e5e5e;
							font-weight: 500;
							width: 50%;
							padding: 10px;
						}

						td {
							text-align: left;
							width: 50%;
							padding: 10px;
							font-family: $nunito;
							font-weight: 400;
							font-size: 14px;
							color: #878787;
						}
					}
				}
			}
		}

		.btns {
			position: relative;
			width: 100%;

			a {
				margin: 0 auto;
				display: table;
				color: $red-500;
				font-weight: bold;
				font-family: $nunito;
				font-size: 14px;
				position: relative;
				&::before {
					content: '';
					background: url('/assets/img/general/download-icon.png') no-repeat center;
					width: 24px;
					height: 21px;
					position: absolute;
					left: -35px;
					top: -50%
				}
			}
		}
	}
}
